const ruAbout = {
    name: "Кратко о нас.",
    content: `Кто мы? <br / > <span className = "font-extrabold"> BIONYX.corp</span> качество, честность и гуманность
            функционирует на основе принципов. Наша команда опытная
            от экспертов, исследователей и медицинских работников
            все они являются эффективными лекарственными средствами
            посвящается созданию. <br / > мы всегда заботимся о потребностях пациентов.
            ставя во главу угла безопасность, качество и эффективность
            разработка лекарств, которые соответствуют высоким стандартам
            выходим. <br / > строгое качество с использованием современных технологий
            назначенная обработка наших продуктов в сочетании с контролем
            мы позаботимся о том, чтобы результаты были достигнуты. Нашей компании
            функция качественная и недорогая для лечения широкого спектра заболеваний
            производство лекарств. <br / > мы проводим инновационные исследования
            и глобальное здравоохранение через производственные технологии
            мы стремимся улучшить результаты. <br / > <span className="font-bold">BIONYX.corp</span>
            наш отдел исследований и разработок (R&D) процесса выпуска
            управляется. <br /> мы новые молекулы, Инновационная медицина
            к открытию формул и передовых методов производства
            вкладываем большие средства. <br / > наша исследовательская команда является международной
            тесно сотрудничает с нормами и процедурами и сфера
            стремится быть впереди своих тенденций. <br /> вклад в Здоровый мир
            чтобы добавить <span className="font-extrabold">BIONYX.corp</span> все виды деятельности в области устойчивого развития
            принимает в качестве приоритетного направления на своих фронтах. <br /> мы естественны
            рациональное использование ресурсов и экологически чистое производство
            экологических процессов, оказывающих воздействие на окружающую среду
            сосредоточимся на сокращении. Отходы в процессе
            сокращение, достижение энергоэффективности и биоразложение
            мы стремимся использовать подручные материалы.`,
            
    content2: `<span className = "font-extrabold" >BIONYX.corp</span> специализируется на различных терапевтических областях, включая кардиологию,
        медицина в онкологии, неврологии и инфекционных заболеваниях
        специализируется на производстве автомобилей. <br / > в нашем портфолио
        лекарства, отпускаемые по рецепту и без рецепта, а также,
        существуют препараты, предназначенные для особых заболеваний. <br / >
        Мы также работаем с учреждениями здравоохранения и исследовательскими организациями
        мы также предоставляем индивидуальные фармацевтические решения. <br / >
         <span className = "font-extrabold" >BIONYX.corp</span> - это не обычная фармацевтическая компания; мы стремимся к глобальному здоровью
        наша команда, основанная на инновациях, для улучшения хранения. <br /> наш
        наши основные ценности-качество, инновации и гуманность. <br / >
        В будущем мы расширим нашу исследовательскую деятельность до персонализированной медицины.,
        новые решения, такие как биотехнология и Цифровое здоровье
        готовы расшириться до направлений. <br /> мы вместе заботимся о здоровье
        мы формируем будущее хранения – с каждым новым достижением.`,

    content3: `4 апреля 2023 г. Соглашение с Mr.Prateek, директором <a href="https://www.indiamart.com/rhydburg-pharmaceuticals-limited/aboutus.html" className="underline text-lightGreen hover:text-white duration-500 ease-in-out">Rhydburg Pharmaceuticals Ltd.</a>, одной из 3 крупнейших компаний Индии.`
}
  
 export default ruAbout;  